import { numberOfDocsViewedCookie, storeNumberOfViewedDocsCookie } from '@/utils/cookieUtils';

const types = {
    SET_XANDR_AD_DATA: 'SET_XANDR_AD_DATA',
    IS_XANDR_AD_LIB_LOADED: 'IS_XANDR_AD_LIB_LOADED',
    HAS_FULL_SCREEN_AD: 'HAS_FULL_SCREEN_AD',
    SET_XANDR_SPONSORING_AD: 'SET_XANDR_SPONSORING_AD',
    IS_FULL_SCREEN_VIDEO_AD: 'IS_FULL_SCREEN_VIDEO_AD',
    VIDEO_AD_HAS_ENDED: 'VIDEO_AD_HAS_ENDED',
    SET_FULL_SCREEN_VIDEO_TIMER: 'SET_FULL_SCREEN_VIDEO_TIMER',
    SET_XANDR_DOCS_VIEWED_COOKIE: 'SET_XANDR_DOCS_VIEWED_COOKIE',
    CAN_REDIRECT_TO_PREMIUM_PAGE: 'CAN_REDIRECT_TO_PREMIUM_PAGE',
};

const state = {
    xandrAdData: {},
    hasFullScreenAd: null,
    isXandrAdLibLoaded: false,
    xandrSponsoringAd: null,
    isFullScreenVideoAd: false,
    fullScreenVideoAdTimer: 15,
    videoAdHasEnded: false,
    numOfDocsViewed: numberOfDocsViewedCookie(),
    numOfDocsAdFree: parseInt(import.meta.env.MIX_XANDR_NUMBER_OF_DOCS_AD_FREE ?? '5', 10),
};

const mutations = {
    [types.SET_XANDR_AD_DATA](state, payload) {
        state.xandrAdData[payload.adType] = { ...payload };
    },
    [types.IS_XANDR_AD_LIB_LOADED](state, payload) {
        state.isXandrAdLibLoaded = payload;
    },
    [types.HAS_FULL_SCREEN_AD](state, payload) {
        state.hasFullScreenAd = payload;
    },
    [types.SET_XANDR_SPONSORING_AD](state, payload) {
        state.xandrSponsoringAd = payload;
    },
    [types.IS_FULL_SCREEN_VIDEO_AD](state, payload) {
        state.isFullScreenVideoAd = payload;
    },
    [types.SET_FULL_SCREEN_VIDEO_TIMER](state, payload) {
        state.fullScreenVideoAdTimer = payload;
    },
    [types.VIDEO_AD_HAS_ENDED](state, payload) {
        state.videoAdHasEnded = payload;
    },
    [types.SET_XANDR_DOCS_VIEWED_COOKIE](state, payload) {
        storeNumberOfViewedDocsCookie(payload);
    },
};

const actions = {
    setXandrAdData({ commit }, data) {
        commit(types.SET_XANDR_AD_DATA, data);
    },
    storeXandrAdLibLoaded({ commit }, data) {
        commit(types.IS_XANDR_AD_LIB_LOADED, data);
    },
    setXandrFullScreenAd({ commit }, data) {
        commit(types.HAS_FULL_SCREEN_AD, data);
    },
    setXandrSponsoringAd({ commit }, data) {
        commit(types.SET_XANDR_SPONSORING_AD, data);
    },
    setXandrFullScreenVideoAd({ commit }, data) {
        commit(types.IS_FULL_SCREEN_VIDEO_AD, data);
    },
    setXandrFullScreenVideoTimer({ commit }, data) {
        commit(types.SET_FULL_SCREEN_VIDEO_TIMER, data);
    },
    setXandrVideoAdHasEnded({ commit }, data) {
        commit(types.VIDEO_AD_HAS_ENDED, data);
    },
    setXandrDocsViewedCookie({ commit }, data) {
        commit(types.SET_XANDR_DOCS_VIEWED_COOKIE, data);
    },
    storeCanRedirectToPremium({ commit }, data) {
        commit(types.CAN_REDIRECT_TO_PREMIUM_PAGE, data);
    },
};

const getters = {
    xandrAdData: (state) => state.xandrAdData,
    isXandrAdLibLoaded: (state) => state.isXandrAdLibLoaded,
    hasFullScreenAd: (state) => state.hasFullScreenAd,
    xandrSponsoringAd: (state) => state.xandrSponsoringAd,
    isFullScreenVideoAd: (state) => state.isFullScreenVideoAd,
    fullScreenVideoAdTimer: (state) => state.fullScreenVideoAdTimer,
    videoAdHasEnded: (state) => state.videoAdHasEnded,
    numOfDocsViewed: (state) => state.numOfDocsViewed,
    numOfDocsAdFree: (state) => state.numOfDocsAdFree,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
