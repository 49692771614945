import { requestLockOrWait } from '@/helpers/locks';
import { timeout } from '@/helpers/misc';
import { HTTP_STATUS } from '@/utils/networkUtils';
import route from '@/router/route';
import { Lock } from '@/utils/locksUtils';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';
// eslint-disable-next-line import/no-restricted-paths
import { backendAxios } from '@/api/backend/index.axios';
import { useAccessToken } from '@/api/utils/composables/accessToken';
import { AxiosError } from 'axios';

export default {
    /**
     * Initiates a token refresh across all browser tabs.
     * Calling it multiple times will result in waiting for the initial refresh to finish.
     * It will block any request from going out until the refresh is finished.
     *
     * If any error occurs, the user is prompted to login again.
     */
    refreshAuthToken() {
        return requestLockOrWait(Lock.TOKEN_REFRESH, () =>
            navigator.locks.request(Lock.SEND_REQUEST, async () => {
                try {
                    await backendAxios.get(route('auth-provider.refresh'));
                } catch (error) {
                    if (error instanceof AxiosError) {
                        const status = error.response?.status;
                        const { accessToken, clear } = useAccessToken();

                        if (status && accessToken.value && status >= 400 && status < 500) {
                            // in case the server returns a client error, but did not remove the AT cookie, we clear it
                            clear();
                        }

                        if (!accessToken.value) {
                            // prompt to login if access token no longer exists
                            // Note: the server is also able to remove the AT & RT cookies through the response header fields
                            EventBus.emit(EventBusEvent.PROMPT_LOGIN);
                        }
                    }

                    throw error;
                }
                // we add a small timeout so that a new token has a bit of time to update everywhere before starting using it
                await timeout(100);
            }),
        );
    },

    /**
     * Only use this function for backend responses as gateway responses behave differently.
     */
    shouldRefreshAuthTokenFor(status: number, url?: string) {
        return status === HTTP_STATUS.UNAUTHORIZED && url !== route('auth-provider.refresh').toString();
    },
};
