import type { Interceptor } from '@/api/utils/interceptors/types';
import { i18n } from '@/plugins/i18n';
import { HTTP_STATUS, isNetworkError } from '@/utils/networkUtils';

/**
 * This interceptor provides a common error response for network errors
 */
export default {
    handleResponseError(error) {
        if (isNetworkError(error)) {
            // eslint-disable-next-line no-param-reassign
            error.response = {
                data: { message: i18n.t('errors.networkError') },
                status: HTTP_STATUS.NETWORK_ERROR,
                statusText: error.message,
                config: error.config!,
                headers: {},
            };
        }

        return Promise.reject(error);
    },
} as Interceptor;
