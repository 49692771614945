import { useCookies } from '@vueuse/integrations/useCookies';

export enum Cookie {
    USER_REFERRAL_ID = 'user_referral_id',
    REMEMBER_FLASHCARD_REWARD_DIALOG = 'flashcard-reward-dialog-cookie',
    AD_BLOCK_USED = 'sd_user_blocks_revenue',
    AD_COOKIE_PREFIX = 'sd-ad-banner-',
    XANDR_VIEWED_DOCS = 'sd-xandr-number-docs-viewed',
    XANDR_VIEWED_FULLSCREEN_AD = 'sd-xandr-viewed-fullscreen-ad',
}

// non-reactive universal-cookie instance
const cookies = useCookies();

export function checkAdCookie(type: string) {
    const val = cookies.get<unknown>(`${Cookie.AD_COOKIE_PREFIX}${type}`);
    return val !== undefined && val;
}

export function numberOfDocsViewedCookie(): number {
    return cookies.get<number>(Cookie.XANDR_VIEWED_DOCS) ?? 0;
}

export function getShowFullScreenAdNumberOfViewedDocsCookie(): boolean {
    const numberOfDocs = numberOfDocsViewedCookie();

    return !numberOfDocs || numberOfDocs !== parseInt(import.meta.env.MIX_XANDR_MAX_NUMBER_OF_DOCS_VIEWED, 10);
}

/*
 * For xandr fullscreen ads
 * */
export function storeNumberOfViewedDocsCookie(number: number): void {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 1);
    const previousNumber = numberOfDocsViewedCookie();
    const newNum =
        previousNumber >= parseInt(import.meta.env.MIX_XANDR_MAX_NUMBER_OF_DOCS_VIEWED, 10)
            ? 0
            : number + previousNumber;

    cookies.set(Cookie.XANDR_VIEWED_DOCS, newNum, {
        expires: new Date(expiry),
        path: '/',
    });
}

export function storeXandrFullScreenAdViewed(val: boolean): void {
    const today = new Date();
    const expiry = today.setDate(today.getDate() + 1);

    cookies.set(Cookie.XANDR_VIEWED_FULLSCREEN_AD, val, { expires: new Date(expiry), path: '/' });
}

export function checkFullScreenViewedCookie(): boolean {
    const val = cookies.get<boolean>(Cookie.XANDR_VIEWED_FULLSCREEN_AD);
    return val !== undefined && val;
}
