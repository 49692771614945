import type { AxiosError } from 'axios';

export enum HTTP_STATUS {
    NETWORK_ERROR = 0,
    SUCCESS = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    UNPROCESSABLE_ENTITY = 422,
    TOO_MANY_REQUESTS = 429,
    MAINTENANCE = 503,
    INTERNAL_SERVER_ERROR = 500,
}

export function isNetworkError(error: AxiosError) {
    return (
        (error.response === undefined && error.message === 'Network Error') ||
        // this status is injected by the networkErrorInterceptor
        error.response?.status === HTTP_STATUS.NETWORK_ERROR
    );
}
