import { BREAKPOINT } from '@/utils/uiUtils';
import store from '@/store';
import { ADSLOTS } from '@/utils/xandrUtils';
import isTheServiceAllowed from '@helpers/userCentrics';
import { remove } from 'lodash-es';
import { checkFullScreenViewedCookie, getShowFullScreenAdNumberOfViewedDocsCookie } from '@/utils/cookieUtils';

export const XANDR_ADLIB_URL = 'https://www.asadcdn.com/adlib/pages/studydrive.js';

const TARGETING_VALUES = {
    university: [],
    university_country: [],
    degree_type: [],
    uni_start_semester: [],
    uni_majors: [],
    courses: [],
    course_page_id: '',
    uni_specific_major: [],
    language: '',
    gender: '',
    study_location: [],
    user_id: '',
    uploaded_doc_count: '',
    course_expert: '',
    job_seeker_status: '',
    group_type: '',
    job_board_clicks: '',
    karma_points: '',
    credits: '',
    is_pupil: '',
};

const pagesWithBottomFixedAd = ['course', 'newsfeed', 'groups'];
let lastPageName = '';
let XANDR_LIB_LOADED = false;
const adsOnDemand = [];
let scaleValue = 1;

function setFullScreenStoreFlag() {
    // in case we have adblockers flag, we dont need to set the flag to destroy fullscreen ad component
    if (!store.getters['ad/hasAdBlocker']) {
        store.dispatch('xandr/setXandrFullScreenAd', false);
    }
}

function mapPageName(page) {
    const pageName = page?.name.replace(/.index|.show/g, '');
    const tabName = page?.hash.replace('#', '');

    if (pageName === 'course' && tabName === 'flashcards') {
        return 'flashcards_index';
    }
    if (pageName === 'course' && tabName === 'documents') {
        return 'documents_index';
    }
    if (pageName === 'document') {
        return 'documents_page';
    }

    if (pageName === 'group' && tabName === 'discussion') {
        return 'groups';
    }

    if (pageName === 'newsfeed') {
        return 'newsfeed';
    }

    if (pageName === 'dashboard') {
        return 'dashboard';
    }

    if (pageName === 'course' && tabName === 'discussion') {
        return 'course';
    }

    return '';
}

function getAdPlacementByPageName(pageName) {
    const isLoggedIn = store.getters['auth/isLoggedIn'];

    switch (pageName) {
        case 'newsfeed':
            return {
                ...(isLoggedIn
                    ? {
                          m: ['banner', 'bottom_fixed_banner'],
                          d: ['mrec'],
                      }
                    : { m: [], d: [] }),
            };
        case 'dashboard':
            return {
                ...(isLoggedIn
                    ? {
                          m: [
                              'banner_btf',
                              'banner_btf_2',
                              'banner_btf_3',
                              'banner_btf_4',
                              'banner_btf_5',
                          ],
                          d: [
                              'billboard_btf',
                              'billboard_btf_2',
                              'billboard_btf_3',
                              'billboard_btf_4',
                              'billboard_btf_5',
                          ],
                      }
                    : { m: [], d: [] }),
            };
        case 'course':
            return {
                ...(isLoggedIn
                    ? {
                          m: ['banner', 'sponsored_course_header', 'bottom_fixed_banner'],
                          d: [
                              'mrec',
                              'sponsored_course_header',
                          ],
                      }
                    : {
                          m: ['sponsored_course_header'],
                          d: ['sponsored_course_header'],
                      }),
            };
        case 'groups':
            return {
                ...(isLoggedIn
                    ? {
                          m: ['banner', 'bottom_fixed_banner', 'sponsored_course_header'],
                          d: ['mrec', 'sponsored_course_header'],
                      }
                    : { m: ['sponsored_course_header'], d: ['sponsored_course_header'] }),
            };
        case 'flashcards_index':
            return {
                ...(isLoggedIn
                    ? {
                          m: ['banner', 'sponsored_course_header'],
                          d: ['mrec', 'sponsored_course_header'],
                      }
                    : { m: [], d: [] }),
            };
        case 'documents_index':
            return {
                ...(isLoggedIn
                    ? {
                          m: ['banner', 'sponsored_course_header'],
                          d: ['mrec', 'sponsored_course_header'],
                      }
                    : { m: [], d: [] }),
            };
        case 'documents_page':
            return {
                ...(isLoggedIn
                    ? {
                          m: [
                              'banner_btf',
                              'banner_btf_2',
                              'fullpage_video_mew',
                          ],
                          d: ['billboard_btf', 'billboard_btf_2', 'fullpage_video'],
                      }
                    : { m: [], d: [] }),
            };
        default:
            return {
                m: [],
                d: [],
            };
    }
}

function xandrTargeting() {
    // basic user info
    TARGETING_VALUES.user_id = sdWindow.user?.userid;
    TARGETING_VALUES.gender = sdWindow.user?.gender;
    TARGETING_VALUES.language = sdWindow.user?.local;
    TARGETING_VALUES.credits = sdWindow.user?.gamification.credits.total;
    TARGETING_VALUES.karma_points = sdWindow.user?.gamification.karma.total;
    TARGETING_VALUES.course_expert = sdWindow.user?.is_ke;
    TARGETING_VALUES.uploaded_doc_count = sdWindow.user?.total_uploads;
    TARGETING_VALUES.job_seeker_status = sdWindow.user?.isJobSeeker;
    TARGETING_VALUES.is_pupil = sdWindow.user?.is_pupil;

    const course = sdWindow.course?.id;
    if (course) {
        TARGETING_VALUES.course_page_id = course;
    }

    const group = sdWindow.group?.id;
    if (group) {
        TARGETING_VALUES.group_type = group;
    }

    // studies
    const studies = sdWindow.user?.studies;
    if (studies && studies.length > 0) {
        const primaryStudy = studies.filter((study) => {
            return study.primary;
        });

        TARGETING_VALUES.university = studies.map((study) => {
            return study.uniId;
        });
        TARGETING_VALUES.university_country = studies.map((studyCountry) => {
            return studyCountry.country_id;
        });
        TARGETING_VALUES.uni_start_semester = primaryStudy[0].semester.id;
        TARGETING_VALUES.study_location = primaryStudy[0].sanitized_city;

        const majorsArray = [];
        const degreeIds = [];
        const majorIds = [];
        const usmIds = [];
        studies.forEach((majors) => majorsArray.push(majors.majors));

        majorsArray.flat().forEach((majors) => {
            if (majors.type_id) {
                degreeIds.push(majors.type_id);
            }
            if (majors.related) {
                majorIds.push(majors.related.id);
                usmIds.push(majors.id);
            } else {
                majorIds.push(majors.id);
            }
        });
        TARGETING_VALUES.degree_type = degreeIds;
        TARGETING_VALUES.uni_specific_major = usmIds;
        TARGETING_VALUES.uni_majors = majorIds;
    }

    const courses = sdWindow.courses?.data;
    if (courses) {
        TARGETING_VALUES.courses = courses.map((courseItem) => {
            return courseItem.id;
        });
    }

    const appEnv = import.meta.env.MIX_XANDR_ENV;
    const prefix = appEnv === 'live' ? '' : 'staging_';

    return Object.keys(TARGETING_VALUES)
        .map((item) => {
            return `${prefix}${[item]}=${TARGETING_VALUES[item]}`;
        })
        .join(';');
}

export function getResizedAds() {
    const view = window.innerWidth < BREAKPOINT.MD ? 'm' : 'd';
    const isAdLibLoaded = store.getters['xandr/isXandrAdLibLoaded'];

    if (isAdLibLoaded) {
        const adPlacements = getAdPlacementByPageName(window.adSSetup?.pageName)[view];

        if (window.adSSetup?.pageName === 'documents_page') {
            remove(adPlacements, (adType) => {
                return adType === 'fullpage_video' || adType === 'fullpage_video_mew';
            });
            setFullScreenStoreFlag();
        }

        const adSlots = adPlacements.reduce((obj, item) => {
            return { ...obj, [item]: ADSLOTS[item] };
        }, {});

        if (adsOnDemand.length > 0) {
            adsOnDemand.forEach((item) => {
                adPlacements.push(item);
            });
        }

        window.adSSetup.adPlacements.forEach((item) => {
            window.ASCDP?.adS.removeAd(item);
        });

        window.adSSetup.adPlacements = adPlacements;
        window.adSSetup.adSlotSizes = adSlots;
        window.adSSetup.view = view;

        let ev;

        try {
            ev = new CustomEvent('pageRefresh', {});
        } catch (err) {
            ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('pageRefresh', true, true, {});
        }
        document.dispatchEvent(ev);
    }
}

export function setup(pageName, consentData = 'false') {
    const view = window.innerWidth < BREAKPOINT.MD ? 'm' : 'd';
    const targetData = consentData === true || consentData === 'true' ? xandrTargeting() : '';

    const adPlacements = getAdPlacementByPageName(pageName)[view];

    /*
     * User should only see the fullscreen when enters the first doc page, this logic will remove fullscreen placement.
     * After 5 docs we will reset the value to 1 and show again the fullscreen ad
     * Removing the placement, we don't request this type of ad placements
     * */

    const numberOfDocs = store.getters['xandr/numOfDocsViewed'];
    const numberOfDocsAdFree = store.getters['xandr/numOfDocsAdFree'];
    const docOwner = store.getters['document/currentDocument']?.user_is_uploader;
    const isNewUser = sdWindow?.user?.is_new_user;
    const userId = sdWindow?.user?.userid;
    /* AB TEST */
    if (
        docOwner ||
        (userId % 2 !== 0 && isNewUser && numberOfDocs < numberOfDocsAdFree) ||
        (getShowFullScreenAdNumberOfViewedDocsCookie() && checkFullScreenViewedCookie())
    ) {
        remove(adPlacements, (adType) => {
            return adType === 'fullpage_video' || adType === 'fullpage_video_mew';
        });
    }

    const adSlots = adPlacements.reduce((obj, item) => {
        return { ...obj, [item]: ADSLOTS[item] };
    }, {});

    if (adsOnDemand.length > 0) {
        adsOnDemand.forEach((item) => {
            adPlacements.push(item);
        });
    }

    const prevAdPlacement = window.adSSetup?.adPlacements;

    window.adSSetup = {
        view,
        partners: false,
        adPlacements,
        adSlotSizes: JSON.parse(JSON.stringify(adSlots)),
        placeholder: {
            disablePlaceholders: true,
            default: {
                'border-color': '#EEEDE8',
                'background-color': '#F9F9F7',
                admarkPosition: 'bottom right',
                color: '#BCBCBC',
                'font-size': '12px',
                'font-family': 'Tahoma',
            },
        },
        colorBg: true,
        bgClick: true,
        hasVideoPlayer: true,
        isArticle: true,
        pageName,
        stickySky: false,
        target: targetData,
        iabTax: 'IAB2,IAB2-1,1,32',
    };

    if (window.ASCDP && pageName !== 'newsfeed') {
        prevAdPlacement.forEach((item) => {
            window.ASCDP.adS.removeAd(item);
        });

        let ev;

        try {
            ev = new CustomEvent('pageRefresh', {});
        } catch (err) {
            ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('pageRefresh', true, true, {});
        }
        document.dispatchEvent(ev);
    }

    if (pagesWithBottomFixedAd.includes(pageName)) {
        store.dispatch('ad/shouldShowBottomFixed', true);
    } else {
        store.dispatch('ad/shouldShowBottomFixed', false);
    }
}

function renderScript() {
    if (!document.getElementById('xandrJS')) {
        const element = document.createElement('script');
        element.setAttribute('src', XANDR_ADLIB_URL);
        element.setAttribute('type', 'text/javascript');
        element.setAttribute('id', 'xandrJS');
        document.head.appendChild(element);
    }
}

function blockTrackHtmlTag() {
    // This code was provided by AS and is used for their adBlocker detection.
    if (!document.getElementById('xandrBlockTrackHtmlTag')) {
        const { adSSetup } = window;
        const screenSize = window.innerWidth <= 800 ? 'm' : 'd';
        const view = adSSetup ? adSSetup.view : screenSize;
        const filename = view === 'd' ? 's' : 'm';
        const x = document.createElement('img');
        const p = document.createElement('img');

        p.style.cssText =
            'display:none;visibility:hidden;position:absolute;left:-99999px;top:-99999px;width:0;height:0;';
        // eslint-disable-next-line func-names
        p.onerror = function () {
            x.src = `https://www.asadcdn.com/bt/${filename}.png?b=1`;
            store.dispatch('ad/updateUserHasAdBlocker', true);
        };

        // eslint-disable-next-line no-multi-assign,func-names
        p.onreadystatechange = p.onload = function () {
            if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                x.src = `https://www.asadcdn.com/bt/${filename}.png?b=0`;
                p.onload = null;
                p.onreadystatechange = null;
            }
        };
        p.src = 'https://acdn.adnxs.com/ast/static/bar.jpg';
        p.id = 'xandrBlockTrackHtmlTag';
        document.body.appendChild(p);

        // eslint-disable-next-line func-names
        x.onerror = function () {
            store.dispatch('ad/updateUserHasAdBlocker', true);
        };

        const elementNoScript = document.createElement('noscript');
        const elementImg = document.createElement('img');

        elementImg.setAttribute('style', 'display:none;');
        elementImg.setAttribute('src', 'https://www.asadcdn.com/bt/s.png?b=2');

        elementNoScript.appendChild(elementImg);
        document.body.appendChild(elementNoScript);

        // eslint-disable-next-line func-names
        // Check if the secondary image fails to load (fallback method for detecting adblock)
        elementImg.onerror = function () {
            store.dispatch('ad/updateUserHasAdBlocker', true);
        };
    }
}

function storeXandrData(data, hasAd) {
    const adType = data.targetId ?? data.contId;
    const creative = data?.response ?? data;
    const xandrAdData = {
        adType,
        hasAd,
        creative_id: creative[0]?.creativeId ?? creative.insertionId,
        advertiser_id: hasAd ? data.advertiserId : null,
    };

    // store xandr ad data
    store.dispatch('xandr/setXandrAdData', { ...xandrAdData });

    // Dsa
    const dsaData = {
        ...xandrAdData,
        isReported: false,
        canShowReportModal: hasAd && !(adType === 'fullpage_video' || adType === 'fullpage_video_mew'),
        xandrCreativeData: {
            reported_item_id: xandrAdData.creative_id,
            reported_item_type_id: 1,
        },
    };

    store.dispatch('dsa/shouldShowReportModal', { ...dsaData });
}

/**
 * Due to adLib limitations, for video ads in fullscreen we will need to use appnexus Events to be able
 * to get the video response and build the ad.
 */
export function checkAdAvailable() {
    /* eslint-disable */
    if (window.apntag?.onEvent && window.ASCDP?.adS) {
        window.apntag.onEvent('adAvailable', (event) => {
            if (
                (event.targetId === 'fullpage_video' || event.targetId === 'fullpage_video_mew') &&
                event.adType === 'video'
            ) {
                try {
                    // set the hidden countdown to video duration
                    store.dispatch('xandr/setXandrFullScreenVideoAd', true);
                    store.dispatch('xandr/setXandrFullScreenVideoTimer', event.video.duration / 1000);
                    window.ASCDP.adS?.sendEvent('adInfo', window.ASCDP.adS?.adElts[event.targetId]);
                } catch (e) {
                    setFullScreenStoreFlag();
                }
            }
            // temporary fix to remove the extra height we get when xandr inserts new ads
            const slotSpacerId = event.targetId + '_slotSpacer';
            const slotSpacerElement = document.getElementById(slotSpacerId);
            if (slotSpacerElement) {
                slotSpacerElement.style.display = 'none'; // hide the element
            }
        });

        // we will use this event from xandr to help us know when the ad is rendered in our page
        window.apntag.onEvent('adLoaded', (event) => {
            if (event.targetId === 'fullpage_video' || event.targetId === 'fullpage_video_mew') {
                // since axel springer injects some styles to their divs, making them visible we need to set it to hidden while doing the scalling calculation
                document.getElementById(event.targetId).children[0].style.visibility = 'hidden';
                setTimeout(() => {
                    adjustScalling(event.targetId);
                }, 1000);
            }
        });
    }
    /* eslint-enable */
}

/*
 * This function is a bypass to axel springer rendering issues
 * In fullscreen ads, axel springer ads some styling to the divs making them break the UI depending on the screen size/zoom
 * This function will help us scale the image/video for the user to be able to interact with the ad
 * We will be checking if the main div (in xandrFullScreenWrapper component) gets a height greater than 95% and scale the div only on that cases
 * */
export function adjustScalling(adSlot) {
    try {
        const wrapper = document.getElementById('xandr-wrapper');
        const containerWrapper = wrapper.parentElement;
        if ((wrapper.clientHeight * scaleValue) / containerWrapper.clientHeight >= 0.95 && scaleValue > 0) {
            scaleValue -= 0.1;
            wrapper.style.transform = `scale(${scaleValue})`;
            requestAnimationFrame(() => {
                adjustScalling(adSlot);
            });
        } else {
            document.body.style.overflow = 'hidden';
            store.dispatch('xandr/setXandrFullScreenAd', true);
            if (!store.getters['ad/hasAdBlocker']) {
                // this will be important only for video ads, since axel springer always ads visibility to the div
                document.getElementById(adSlot).children[0].style.visibility = 'visible';
            }
        }
    } catch (e) {
        setFullScreenStoreFlag();
    }
}

export function initialize(pageName, consentData) {
    setup(pageName, consentData);
    renderScript();
    blockTrackHtmlTag();
}

export function renderAd(adType, adSizes) {
    // premium users will not have the adSSetup since we return early in the default function
    if (!window.adSSetup || typeof adSSetup === 'undefined') {
        return;
    }

    let ev;
    const adSlotSizes = window.adSSetup?.adSlotSizes;

    if (adSlotSizes && !adSlotSizes[adType]) {
        adSlotSizes[adType] = [
            {
                minWidth: 1,
                sizes: adSizes,
            },
        ];
    }
    // eslint-disable-next-line no-undef
    if (!adsOnDemand.includes(adType) && adSSetup.pageName !== 'documents_page') {
        // eslint-disable-next-line no-undef
        adsOnDemand.push(adType);
    }
    try {
        ev = new CustomEvent('renderAd', { detail: adType });
    } catch (err) {
        ev = document.createEvent('CustomEvent');
        ev.initCustomEvent('renderAd', true, true, { detail: adType });
    }
    document.dispatchEvent(ev);
}

// eslint-disable-next-line func-names
export default function (pageName) {
    const isPremiumUser = store.getters['auth/user']?.is_premium;
    const isPremiumSystemEnabled = store.getters['auth/user']?.is_premium_system_enabled;
    if (isPremiumSystemEnabled && isPremiumUser) {
        return;
    }

    const consentData = localStorage.getItem('xandrConsent');
    const newPageName = mapPageName(pageName);

    // TODO: clean up this event
    window.addEventListener('adInfo', (adSlot) => {
        if (import.meta.env.MIX_XANDR_FE_LOG === 'true') {
            // eslint-disable-next-line no-console
            console.log(adSlot.detail);
        }

        storeXandrData(adSlot.detail, adSlot.detail.hasAd);

        /* Course Sponsoring ad */
        if (adSlot.detail.contId === 'sponsored_course_header' && adSlot.detail.hasAd) {
            store.dispatch('xandr/setXandrSponsoringAd', adSlot.detail.sponsored_course);
        }

        /* Full screen ad */
        if (adSlot.detail.contId === 'fullpage_video' || adSlot.detail.contId === 'fullpage_video_mew') {
            try {
                if (adSlot.detail.hasAd) {
                    if (adSlot.detail?.response?.[0]?.type !== 'video') {
                        const contElement = document.getElementById(adSlot.detail.contId);
                        contElement?.parentElement?.removeAttribute('style');
                        contElement?.removeAttribute('style');
                    } else {
                        // sometimes depending on the screen size, xandr video logic adds an overflow-x, we need to prevent that by removing it
                        // eslint-disable-next-line no-lonely-if
                        if (adSlot.detail?.playerDiv?.style) {
                            // eslint-disable-next-line no-param-reassign
                            adSlot.detail.playerDiv.style.overflow = 'hidden';
                        }
                    }
                } else {
                    setFullScreenStoreFlag();
                }
            } catch (e) {
                setFullScreenStoreFlag();
            }
        }

        // The adlib will overwrite certain css when the ads get rendered so we need to
        // apply it after the ads are rendered
        if (
            (adSlot.detail.contId === 'billboard' || adSlot.detail.contId === 'billboard_btf') &&
            adSlot.detail.hasAd &&
            window.adSSetup.pageName === 'documents_page'
        ) {
            const targetClass = document.getElementsByClassName('document-xandr-banner-wrapper');
            targetClass[0].classList.remove('flex', 'justify-center');
            targetClass[1].classList.remove('flex', 'justify-center');
        }
    });
    // TODO: clean up this event
    window.addEventListener('adlibLoaded', () => {
        // once the adLib is loaded we need to emit an event submitting the user consent (for every page impression)
        const consentObj = { consent: consentData };
        window.top.dispatchEvent(new CustomEvent('customConsentSubmitted', { detail: consentObj }));

        XANDR_LIB_LOADED = true;

        /* Due to adlib limitations, we need to use for some cases appnexus lib, this lib is injected by adlib, since we can't have "control"
         * we are not able to know when this has been loaded, for that we need to check it using the logic bellow
         * */
        const detectApnTag = setInterval(() => {
            if (window.apntag && window.apntag.onEvent) {
                store.dispatch('xandr/storeXandrAdLibLoaded', true).then(() => {
                    checkAdAvailable();
                });
                clearInterval(detectApnTag);
            }
        }, 100);

        /* to avoid an infinite loop or unnecessary checks we will have this timeout
         * to also clear the interval after a certain amount of time */
        setTimeout(() => {
            clearInterval(detectApnTag);
        }, 10000);
    });
    // TODO: clean up this event
    window.addEventListener('UC_UI_CMP_EVENT', async (e) => {
        const eventType = e.detail.type;
        if (eventType === 'SAVE' || eventType === 'ACCEPT_ALL') {
            const xandrConsent = await isTheServiceAllowed('Xandr');
            localStorage.setItem('xandrConsent', xandrConsent);
            initialize(newPageName, xandrConsent);
        }
    });

    if (newPageName !== lastPageName) {
        initialize(newPageName, consentData);
    }
    lastPageName = newPageName;
}
