import type { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import authTokenUtils from '@/utils/authTokenUtils';
import type { Interceptor } from '@/api/utils/interceptors/types';
import route from '@/router/route';
import { Lock } from '@/utils/locksUtils';

const tokenRefreshUrl = route('auth-provider.refresh').toString();

function isTokenRefreshRequest(config: InternalAxiosRequestConfig) {
    const url = new URL(config.url ?? '', config.baseURL ?? window.location.origin);
    return url.href.startsWith(tokenRefreshUrl);
}

/**
 * This interceptor initiates a token refresh if tokens have expired and delays responses/requests till refresh finished
 */
export default (instance: AxiosInstance, shouldDoTokenRefresh: (response: AxiosResponse) => boolean): Interceptor => ({
    /**
     * handles token refresh error and refreshes tokens if needed
     */
    async handleResponseError(error) {
        const { response, config } = error;

        if (!response || !shouldDoTokenRefresh(response) || !config || isTokenRefreshRequest(config)) {
            return Promise.reject(error);
        }

        try {
            await authTokenUtils.refreshAuthToken();
            // As the previous request was already transformed and we don't want further manipulation, we remove any transformation
            config.transformRequest = [];
            // unset previous Authorization header so the new one is injected by the instance defaults
            delete config.headers.Authorization;
            // re-do the request
            return instance.request(config);
        } catch (e) {
            return Promise.reject(e);
        }
    },

    /**
     * delays requests till any ongoing token refresh has finished
     */
    async handleRequest(config) {
        if (!isTokenRefreshRequest(config)) {
            /*
             * By requesting and directly freeing the send request lock, we can pause our requests if the lock might be hold due to a token refresh.
             *   The downside of doing this means that our requests across tabs would be dispatched sequentially and not in parallel.
             *   But this might be negligible, as users can only interact with one tab at a time and each tab runs in a single thread which already runs code sequentially.
             */
            await navigator.locks.request(Lock.SEND_REQUEST, () => undefined);
        }

        return config;
    },
});
