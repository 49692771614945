import { toRef } from 'vue';
import { once } from 'lodash-es';
import { useCommonHeaders } from '@/api/utils/composables/commonHeaders';
import { useAxios } from '@/api/utils/composables/axios';

export const useBackendHeaders = once(() => ({
    ...useCommonHeaders(),
    'X-Requested-With': 'XMLHttpRequest',
}));

export const backendAxios = useAxios(
    toRef({
        // We used XSRC token in the past, but discontinued it.
        // In order to not include it in requests, we specify a cookie name that should not contain data
        xsrfCookieName: 'unset',
        headers: useBackendHeaders(),
        withCredentials: true,
    }),
);
