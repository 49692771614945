import { registerInterceptors } from '@/api/utils/interceptors';
import networkErrorInterceptor from '@/api/utils/interceptors/networkErrorInterceptor';
import maintenanceInterceptor from '@/api/utils/interceptors/maintenanceInterceptor';
import tokenRefreshInterceptor from '@/api/utils/interceptors/tokenRefreshInterceptor';
import tooManyRequestsInterceptor from '@/api/utils/interceptors/tooManyRequestsInterceptor';
import authTokenUtils from '@/utils/authTokenUtils';
import { backendAxios } from '@/api/backend/index.axios';

export { useBackendHeaders } from '@/api/backend/index.axios';

function createBackend() {
    registerInterceptors(backendAxios, [
        networkErrorInterceptor,
        maintenanceInterceptor,
        tokenRefreshInterceptor(backendAxios, (response) =>
            authTokenUtils.shouldRefreshAuthTokenFor(response.status, 'config' in response ? response.config.url : undefined),
        ),
        tooManyRequestsInterceptor,
    ]);

    return backendAxios;
}

export const backend = createBackend();
